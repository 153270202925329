<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-row>
          <v-col
            style="display: flex; justify-content: center; align-items: center"
            cols="12"
            sm="6"
            md="6"
          >
            <img
              :src="src"
              @click="selectFile"
              style="height: 280px; max-width: 100%; cursor: pointer"
              alt=""
            />
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <v-col cols="12" md="12">
              <p>Nombre del banner</p>
              <v-text-field
                rounded
                outlined
                :rules="[rules.required]"
                placeholder="Nombre para identificar el banner"
                v-model.trim="item.name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <p>Descripción</p>
              <v-textarea
                rounded
                placeholder="Descripción del banner"
                v-model="item.description"
                outlined
                rows="2"
              />
            </v-col>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="6">
        <p>Fecha que inicia</p>
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="true"
          :return-value.sync="item.date[0]"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              rounded
              :rules="[rules.required]"
              outlined
              v-on="on"
              placeholder="Fecha de inicio"
              v-model="item.date[0]"
            />
          </template>
          <v-date-picker
            v-model="item.date[0]"
            no-title
            scrollable
            @click:date="setDate1"
          >
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="6">
        <p>Fecha que termina</p>
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="true"
          :return-value.sync="item.date[1]"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              outlined
              rounded
              v-on="on"
              :rules="[rules.required]"
              placeholder="Fecha que termina"
              v-model="item.date[1]"
            />
          </template>
          <v-date-picker
            v-model="item.date[1]"
            no-title
            scrollable
            @click:date="setDate2"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="6">
        <p>Hora Inicial</p>
        <v-select
          v-model="item.timeStart"
          :items="times"
          placeholder="Seleccione la hora inicial"
          outlined
          :rules="[rules.required]"
          dense
          item-value="value"
          item-text="text"
          rounded
        />
      </v-col>
      <v-col cols="12" md="6">
        <p>Hora Final</p>
        <v-select
          v-model="item.timeEnd"
          :items="times"
          placeholder="Seleccione la hora final"
          outlined
          :rules="[rules.required]"
          dense
          item-value="value"
          item-text="text"
          rounded
        />
      </v-col>
    </v-row>

    <input
      class="upload"
      ref="input"
      type="file"
      name="image"
      accept="image/*"
      @change="imageChanged"
    />
  </div>
</template>

<script>
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
// import { db } from "@/firebase";

export default {
  name: "coupon-general",
  props: ["item"],
  data() {
    return {
      menu1: false,
      menu2: false,
      date: [],
      src: require("@/assets/product-default.jpg"),
      file: null,
      times: [
        { text: "07:00 AM", value: "07:00:00" },
        { text: "07:30 AM", value: "07:30:00" },
        { text: "08:00 AM", value: "08:00:00" },
        { text: "08:30 AM", value: "08:30:00" },
        { text: "09:00 AM", value: "09:00:00" },
        { text: "09:30 AM", value: "09:30:00" },
        { text: "10:00 AM", value: "10:00:00" },
        { text: "10:30 AM", value: "10:30:00" },
        { text: "11:00 AM", value: "11:00:00" },
        { text: "11:30 AM", value: "11:30:00" },
        { text: "12:00 PM", value: "12:00:00" },
        { text: "12:30 PM", value: "12:30:00" },
        { text: "01:00 PM", value: "13:00:00" },
        { text: "01:30 PM", value: "13:30:00" },
        { text: "02:00 PM", value: "14:00:00" },
        { text: "02:30 PM", value: "14:30:00" },
        { text: "03:00 PM", value: "15:00:00" },
        { text: "03:30 PM", value: "15:30:00" },
        { text: "04:00 PM", value: "16:00:00" },
        { text: "04:30 PM", value: "16:30:00" },
        { text: "05:00 PM", value: "17:00:00" },
        { text: "05:30 PM", value: "17:30:00" },
        { text: "06:00 PM", value: "18:00:00" },
        { text: "06:30 PM", value: "18:30:00" },
        { text: "07:00 PM", value: "19:00:00" },
        { text: "07:30 PM", value: "19:30:00" },
        { text: "08:00 PM", value: "20:00:00" },
        { text: "08:30 PM", value: "20:30:00" },
        { text: "09:00 PM", value: "21:00:00" },
        { text: "09:30 PM", value: "21:30:00" },
        { text: "10:00 PM", value: "22:00:00" },
        { text: "10:30 PM", value: "22:30:00" },
        { text: "11:00 PM", value: "23:00:00" },
        { text: "11:30 PM", value: "23:30:00" },
        { text: "12:00 AM", value: "00:00:00" },
        { text: "12:30 AM", value: "00:30:00" },
        { text: "01:00 AM", value: "01:00:00" },
        { text: "01:30 AM", value: "01:30:00" },
        { text: "02:00 AM", value: "02:00:00" },
        { text: "02:30 AM", value: "02:30:00" },
        { text: "03:00 AM", value: "03:00:00" },
        { text: "03:30 AM", value: "03:30:00" },
        { text: "04:00 AM", value: "04:00:00" },
        { text: "04:30 AM", value: "04:30:00" },
        { text: "05:00 AM", value: "05:00:00" },
        { text: "05:30 AM", value: "05:30:00" },
        { text: "06:00 AM", value: "06:00:00" },
        { text: "06:30 AM", value: "06:30:00" },
      ],

      rules: {
        required: (value) => !!value || "Obligatorio",
        min: (v) => (v && v > 0) || "Debe ingresar un valor numerico mayor a 0",
        minNegative: (v) =>
          (v && v > -1) || "Debe ingresar un valor mayor o igual a 0",
      },
    };
  },
  computed: {
    today() {
      return moment(new Date()).format("YYYY-MM-DD");
    },
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    selectFile() {
      this.$refs.input.click();
    },

    setDate1(date) {
      this.$refs.menu1.save(date);
    },
    setDate2(date) {
      this.$refs.menu2.save(date);
    },

    imageChanged(e) {
      if (!e.target.files[0]) {
        return;
      }

      this.item.file = e.target.files[0];

      var reader = new FileReader();

      reader.onload = (e) => {
        this.src = e.target.result;
      };

      reader.readAsDataURL(this.item.file);
    },
  },
  mounted() {
    if(this.item.image && this.item.image.original) {
      this.src = this.item.image.original
    }
  },
};
</script>


<style scoped>
.divider {
  margin-top: 20px;
  margin-bottom: 20px;
}

.fa-random {
  cursor: pointer;
  font-size: 25px;
  color: gray;
}

.upload {
  display: none;
}
</style>