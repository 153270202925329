<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <p>Tipo de banner</p>
        <v-autocomplete
          v-model="item.type"
          :items="bannerType"
          placeholder="Seleccione tipo de banner"
          outlined
          dense
          item-value="value"
          item-text="text"
          rounded
        />
      </v-col>

      <v-col
        v-if="['business', 'product', 'category'].includes(item.type)"
        cols="12"
        md="6"
      >
        <p>Seleccionar ciudad</p>
        <v-autocomplete
          v-model="selectedCity"
          :items="cities"
          placeholder="Seleccione la ciudad"
          outlined
          @change="getCategories"
          :rules="[rules.required]"
          dense
          item-value=".key"
          item-text="name"
          rounded
        />
      </v-col>

      <v-col v-if="['coupon'].includes(item.type)" cols="12" md="6">
        <p>Ingrese el código del cupón</p>
        <v-text-field
          v-model="item.target.coupon"
          placeholder="Ingrese el código del cupón"
          outlined
          :rules="[rules.required]"
          dense
          rounded
        />
      </v-col>

      <v-col
        v-if="['business', 'product', 'category'].includes(item.type)"
        cols="12"
        md="6"
      >
        <p>Seleccionar categoría</p>
        <v-autocomplete
          v-model="item.target.categoryId"
          :items="filterCategories"
          placeholder="Seleccione la categoría"
          outlined
          @change="getBusinesses"
          :rules="[rules.required]"
          dense
          item-value=".key"
          item-text="name"
          rounded
        >
          <template v-slot:selection="data">
            {{ data.item.name }}
          </template>
          <template v-slot:item="data">
            <template>
              <v-list-item-avatar>
                <img v-if="data.item.image" :src="data.item.image.original" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.item.name }}
                </v-list-item-title>
                <small style="color: #FF1744">{{ data.item.cityName }}</small>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col
        v-if="['business', 'product'].includes(item.type)"
        cols="12"
        md="6"
      >
        <p>Seleccionar comercio</p>
        <v-autocomplete
          v-model="item.target.businessId"
          :items="filterBusinesses"
          :disabled="!item.target.categoryId || gettingBusinesses"
          placeholder="Seleccione el comercio"
          outlined
          :loading="gettingBusinesses"
          @change="getProducts"
          :rules="[rules.required]"
          dense
          item-value=".key"
          item-text="shortName"
          rounded
        >
          <template v-slot:selection="data">
            {{ data.item.shortName }}
          </template>
          <template v-slot:item="data">
            <template>
              <v-list-item-avatar>
                <img v-if="data.item.image" :src="data.item.image.original" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.item.shortName }}
                </v-list-item-title>
                <small style="color: #FF1744">{{ data.item.cityName }}</small>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col v-if="['product'].includes(item.type)" cols="12" md="6">
        <p>Seleccionar producto</p>
        <v-autocomplete
          v-model="item.target.productId"
          :items="products"
          placeholder="Seleccione producto"
          outlined
          :disabled="!item.target.businessId || gettingProducts"
          :rules="[rules.required]"
          dense
          :loading="gettingProducts"
          item-value=".key"
          item-text="name"
          rounded
        >
          <template v-slot:selection="data">
            {{ data.item.name }}
          </template>
          <template v-slot:item="data">
            <template>
              <v-list-item-avatar>
                <img
                  v-if="data.item.images && data.item.images.length"
                  :src="data.item.images[0]"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.item.name }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { db } from "@/firebase";

export default {
  name: "banner-target",
  props: ["item"],
  data() {
    return {
      rules: {
        required: (value) => !!value || "Obligatorio",
        min: (v) => (v && v > 0) || "Debe ingresar un valor numerico mayor a 0",
        minNegative: (v) =>
          (v && v > -1) || "Debe ingresar un valor mayor o igual a 0",
      },
      bannerType: [
        { text: "Categoría", value: "category" },
        { text: "Comercio", value: "business" },
        { text: "Cupón", value: "coupon" },
        { text: "Producto", value: "product" },
        { text: "Ninguno", value: "none" },
      ],
      categories: [],
      businesses: [],
      products: [],
      gettingBusinesses: false,
      gettingProducts: false,
      cities: [],
      selectedCity: "",
    };
  },
  watch: {
    "item.type"(e) {
      this.item.target = {
        categoryId: "",
        businessId: "",
        coupon: "",
        productId: ""
      };
    },
  },
  computed: {
    filterBusinesses() {
      return this.businesses.map((item) => {
        let cities = this.cities.filter(
          (c) => item.cities && item.cities.includes(c[".key"])
        );

        if (cities && cities.length) {
          cities = cities.map((i) => {
            return i.name;
          });
        }

        return {
          shortName: item.shortName,
          image: item.logo,
          cityName: cities ? cities.join(",") : "",
          ".key": item[".key"],
        };
      });
    },

    filterCategories() {
      return this.categories.map((item) => {
        let city = this.cities.find((c) => c[".key"] == item.city);
        return {
          name: item.name,
          image: item.image,
          cityName: city ? city.name : "",
          ".key": item[".key"],
        };
      });
    },
  },
  methods: {
    async getBusinesses() {
      if (this.item.target.categoryId) {
        this.gettingBusinesses = true;
        await this.$binding(
          "businesses",
          db
            .collection("businesses")
            .where(
              "ochoCategories",
              "array-contains",
              this.item.target.categoryId
            )
            .where("deleted", "==", false)
        );
        this.gettingBusinesses = false;
      }
    },
    async getProducts() {
      if (this.item.target.businessId) {
        this.gettingProducts = true;
        await this.$binding(
          "products",
          db
            .collection("products")
            .where("business", "array-contains", this.item.target.businessId)
            .where("deleted", "==", false)
            .where("addon", "==", false)
        );
        this.gettingProducts = false;
      }
    },

    async getCategories() {
      if (this.selectedCity) {
        // this.item.cities = [this.selectedCity];
        await this.$binding(
          "categories",
          db
            .collection("categories")
            .where("city", "==", this.selectedCity)
            .where("deleted", "==", false)
        );
      }
    },
  },
  async mounted() {
    await this.$binding(
      "cities",
      db.collection("cities").where("deleted", "==", false)
    );

    if (this.item.cities && this.item.cities.length) {
      this.selectedCity = this.item.cities[0];
      await this.getCategories();
      await this.getBusinesses();
      if (this.item.type == "product") {
        await this.getProducts();
      }
    }

    // console.log(this.item.target);
  },
};
</script>
