<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">
        <p>Seleccionar países</p>
        <v-autocomplete
          v-model="item.countries"
          :items="countries"
          placeholder="Seleccione paises"
          outlined
          chips
          multiple
          hide-details
          dense
          item-value=".key"
          item-text="name"
          rounded
        />
      </v-col>

      <v-col cols="12" md="12">
        <p>Seleccionar ciudades</p>
        <v-autocomplete
          v-model="item.cities"
          :items="cities"
          placeholder="Seleccione ciudades"
          outlined
          chips
          multiple
          hide-details
          dense
          item-value=".key"
          item-text="name"
          rounded
        />
      </v-col>

      <v-col cols="12" md="12">
        <p>Seleccionar categoría</p>
        <v-autocomplete
          v-model="item.categories"
          :items="getCategories"
          placeholder="Seleccione las categorías"
          outlined
          multiple
          hide-details
          dense
          item-value=".key"
          item-text="name"
          rounded
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              @click="data.select"
            >
              {{ data.item.name }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <template>
              <v-list-item-avatar>
                <img v-if="data.item.image" :src="data.item.image.original" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.item.name }}
                </v-list-item-title>
                <small style="color: #FF1744">{{ data.item.cityName }}</small>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="12" md="12">
        <p>Seleccionar comercios</p>
        <v-autocomplete
          v-model="item.business"
          :items="getBusinesses"
          placeholder="Seleccionar comercio"
          outlined
          multiple
          hide-details
          chips
          dense
          item-value=".key"
          item-text="shortName"
          rounded
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              @click="data.select"
            >
              {{ data.item.shortName }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <template>
              <v-list-item-avatar>
                <img v-if="data.item.image" :src="data.item.image.original" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.item.shortName }}
                </v-list-item-title>
                <small style="color: #FF1744">{{ data.item.cityName }}</small>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { db } from "@/firebase";

export default {
  name: "banner-target",
  props: ["item"],
  data() {
    return {
      rules: {
        required: (value) => !!value || "Obligatorio",
        min: (v) => (v && v > 0) || "Debe ingresar un valor numerico mayor a 0",
        minNegative: (v) =>
          (v && v > -1) || "Debe ingresar un valor mayor o igual a 0",
      },
      categories: [],
      businesses: [],
      products: [],
      countries: [],
      cities: [],
      gettingBusinesses: false,
      gettingProducts: false,
    };
  },
  computed: {
    getCategories() {
      return this.categories.map((item) => {
        let city = this.cities.find((c) => c[".key"] == item.city);
        return {
          name: item.name,
          image: item.image,
          cityName: city ? city.name : "",
          ".key": item[".key"],
        };
      });
    },

    getBusinesses() {
      return this.businesses.map((item) => {
        let cities = this.cities.filter(
          (c) => item.cities && item.cities.includes(c[".key"])
        );

        if (cities && cities.length) {
          cities = cities.map((i) => {
            return i.name;
          });
        }

        return {
          shortName: item.shortName,
          image: item.logo,
          cityName: cities ? cities.join(",") : "",
          ".key": item[".key"],
        };
      });
    },
  },
  methods: {},
  async mounted() {
    await this.$binding(
      "countries",
      db.collection("countries").where("deleted", "==", false)
    );

    await this.$binding(
      "cities",
      db.collection("cities").where("deleted", "==", false)
    );

    await this.$binding(
      "categories",
      db.collection("categories").where("deleted", "==", false)
    );

    await this.$binding(
      "businesses",
      db.collection("businesses").where("deleted", "==", false)
    );
  },
};
</script>
