<template>
  <div id="add-edit-coupon">
    <v-card :loading="loading" class="px-2">
      <v-card-title>
        <span class="headline">
          {{ selectedItem ? "Editar" : "Nuevo" }} banner
        </span>

        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>

      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab key="general"> General </v-tab>
        <v-tab key="categories">Target </v-tab>
        <v-tab key="display">Visualización </v-tab>
        <v-tab key="status"> Estados </v-tab>
      </v-tabs>

      <v-card-text>
        <v-container fluid>
          <v-snackbar top :timeout="3000" v-model="snackbar">
            {{ snackbarText }}
          </v-snackbar>

          <v-tabs-items v-model="tab">
            <v-tab-item key="general">
              <General ref="general" :item="item" />
            </v-tab-item>

            <v-tab-item key="target">
              <Target :item="item" />
            </v-tab-item>

            <v-tab-item key="display">
              <Display :item="item" />
            </v-tab-item>

            <v-tab-item key="status">
              <Status :item="item" />
            </v-tab-item>
          </v-tabs-items>

          <v-row class="pa-0 ma-0 mt-8">
            <v-col cols="6">
              <v-row align="center" justify="start">
                <v-switch
                  v-if="tab != '3'"
                  v-model="item.active"
                  label="Activo"
                ></v-switch>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row align="center" justify="end">
                <v-btn
                  @click="selectedItem ? update() : save()"
                  :loading="loading"
                  class="mt-4"
                  color="primary"
                >
                  Guardar
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapState } from "vuex";
import General from "./tabs/general";
import Target from "./tabs/target";
import Display from "./tabs/display";
import Status from "./tabs/status";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default {
  name: "add-in-app-messaging",
  components: {
    General,
    Target,
    Display,
    Status,
  },
  props: ["selectedItem"],
  data() {
    return {
      tab: null,
      loading: false,
      snackbarText: "",
      snackbar: false,
      item: {
        target: {},
        date: [],
        name: "",
        deleted: false,
        description: "",
        categories: [],
        business: [],
        cities: [],
        countries: [],
        activeFriday: true,
        activeMonday: true,
        activeSaturday: true,
        activeSunday: true,
        activeThursday: true,
        activeTuesday: true,
        activeWednesday: true,
        active: false,
        showInBusiness: true,
        showInCategory: false,
        showInCheckout: false,
        showInCoupons: false,
        showInLobby: false,
      },
    };
  },
  computed: {
    ...mapState(["user"]),
  },

  methods: {
    validate() {
      return new Promise((resolve, reject) => {
        if (!this.item.name) {
          return reject("Debe ingresar el nombre del banner.");
        }

        if (!this.item.date[0] || !this.item.date[1]) {
          return reject("Debe ingresar las fechas de inicio y final.");
        }

        if (!this.item.timeStart || !this.item.timeEnd) {
          return reject("Debe ingresar las horas de inicio y final.");
        }

        if (!this.item.type) {
          this.tab = 1;
          return reject("Debe seleccionar el tipo de banner.");
        }

        if (
          ["category", "product", "business"].includes(this.item.type) &&
          !this.item.target.categoryId
        ) {
          this.tab = 1;
          return reject("Debe seleccionar la categoría.");
        }

        if (
          ["product", "business"].includes(this.item.type) &&
          !this.item.target.businessId
        ) {
          this.tab = 1;
          return reject("Debe seleccionar el comercio.");
        }

        if (
          ["product"].includes(this.item.type) &&
          !this.item.target.productId
        ) {
          this.tab = 1;
          return reject("Debe seleccionar el producto a mostrar.");
        }

        if (this.item.type == "coupon" && !this.item.target.coupon) {
          this.tab = 1;
          return reject("Debe ingresar el código del cupón.");
        }

        if (!this.selectedItem && !this.item.file) {
          this.tab = 0;
          return reject("Debe seleccionar una imágen.");
        }

        return resolve("success");
      });
    },
    generateTimestamp() {
      return new Promise((resolve, reject) => {
        let date = this.item.date[0].split("-");
        this.item.dateStart = fb.firestore.Timestamp.fromDate(
          new Date(
            parseInt(date[0]),
            parseInt(date[1]) - 1,
            parseInt(date[2]),
            parseInt("07"),
            parseInt("00"),
            parseInt("00")
          )
        );
        date = this.item.date[1].split("-");
        this.item.dateEnd = fb.firestore.Timestamp.fromDate(
          new Date(
            parseInt(date[0]),
            parseInt(date[1]) - 1,
            parseInt(date[2]),
            parseInt("19"),
            parseInt("00"),
            parseInt("00")
          )
        );
        resolve(true);
      });
    },
    async save() {
      try {
        let validation = await this.validate();

        await this.generateTimestamp();
        delete this.item.date;
        let file = this.item.file;
        delete this.item.file;

        this.item.createdAt = new Date();
        this.item.createdBy = this.user[".key"];
        this.item.type = this.item.type == "none" ? "" : this.item.type;
        this.item.target.coupon =
          this.item.target && this.item.target.coupon
            ? this.item.target.coupon.toUpperCase()
            : "";

        this.loading = true;

        db.collection("inAppCustomMessaging")
          .add(this.item)
          .then(async (ref) => {
            await this.uploadImage(file, ref.id);
            this.loading = false;
            this.$emit("success");
          })
          .catch((err) => {
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
            this.loading = false;
          });
      } catch (error) {
        this.snackbarText = error;
        this.snackbar = true;
      }
    },

    uploadImage(file, id) {
      return new Promise((resolve, reject) => {
        fb.app()
          .storage(`gs://${process.env.VUE_APP_PROJECT_ID}-in-app-messaging`)
          .ref()
          .child(`${id}/${file.name}`)
          .put(file)
          .then(() => {
            return resolve("success");
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },

    async update() {
      try {
        let validation = await this.validate();
        await this.generateTimestamp();
        let file = this.item.file;

        this.loading = true;

        let payload = {
          target: this.item.target,
          name: this.item.name,
          description: this.item.description,
          categories: this.item.categories,
          business: this.item.business,
          cities: this.item.cities,
          countries: this.item.countries,
          activeFriday: this.item.activeFriday,
          activeMonday: this.item.activeMonday,
          activeSaturday: this.item.activeSaturday,
          activeSunday: this.item.activeSunday,
          activeThursday: this.item.activeThursday,
          activeTuesday: this.item.activeTuesday,
          activeWednesday: this.item.activeWednesday,
          active: this.item.active,
          showInBusiness: this.item.showInBusiness,
          showInCategory: this.item.showInCategory,
          showInCheckout: this.item.showInCheckout,
          showInCoupons: this.item.showInCoupons,
          showInLobby: this.item.showInLobby,
          modifiedAt: new Date(),
          modifiedBy: this.user[".key"],
          dateStart: this.item.dateStart,
          dateEnd: this.item.dateEnd,
          timeEnd: this.item.timeEnd,
          timeStart: this.item.timeStart,
          type: this.item.type == "none" ? "" : this.item.type,
        };

        db.collection("inAppCustomMessaging")
          .doc(this.selectedItem[".key"])
          .update(payload)
          .then(async (ref) => {
            if (file) {
              await this.uploadImage(file, this.selectedItem[".key"]);
            }
            this.loading = false;
            this.$emit("success");
          })
          .catch((err) => {
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
            this.loading = false;
          });
      } catch (error) {
        this.snackbarText = error;
        this.snackbar = true;
      }
    },
  },

  mounted() {
    if (this.selectedItem) {
      this.item = Object.assign({}, this.selectedItem);
      this.item.date = [
        moment.unix(this.item.dateStart.seconds).format("YYYY-MM-DD"),
        moment.unix(this.item.dateEnd.seconds).format("YYYY-MM-DD"),
      ];
    }
  },
};
</script>

<style>
.v-application--is-ltr
  .v-tabs--align-with-title
  > .v-tabs-bar:not(.v-tabs-bar--show-arrows)
  > .v-slide-group__wrapper
  > .v-tabs-bar__content
  > .v-tab:first-child,
.v-application--is-ltr
  .v-tabs--align-with-title
  > .v-tabs-bar:not(.v-tabs-bar--show-arrows)
  > .v-slide-group__wrapper
  > .v-tabs-bar__content
  > .v-tabs-slider-wrapper
  + .v-tab {
  margin-left: 10px;
}
</style>
