<template>
  <div>
    <v-row>
      <v-col cols="12" sm="4" md="4">
        <v-switch
          v-model="item.showInBusiness"
          label="Mostrar en comercios"
        ></v-switch>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-switch
          v-model="item.showInCategory"
          label="Mostrar en categorías"
        ></v-switch>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-switch
          v-model="item.showInCheckout"
          label="Mostrar en checkout"
        ></v-switch>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-switch
          v-model="item.showInCoupons"
          label="Mostrar en cupones"
        ></v-switch>
      </v-col>

      <v-col cols="12" sm="4" md="4">
        <v-switch
          v-model="item.showInLobby"
          label="Mostrar en lobby"
        ></v-switch>
      </v-col>

      <v-col cols="12" sm="4" md="4">
        <v-switch
          v-model="item.activeMonday"
          label="Activo el Lunes"
        ></v-switch>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-switch
          v-model="item.activeTuesday"
          label="Activo el Martes"
        ></v-switch>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-switch
          v-model="item.activeWednesday"
          label="Activo el Miercoles"
        ></v-switch>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-switch
          v-model="item.activeThursday"
          label="Activo el Jueves"
        ></v-switch>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-switch
          v-model="item.activeFriday"
          label="Activo el Viernes"
        ></v-switch>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-switch
          v-model="item.activeSaturday"
          label="Activo el Sábado"
        ></v-switch>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-switch
          v-model="item.activeSunday"
          label="Activo el Domingo"
        ></v-switch>
      </v-col>

      <v-col cols="12" sm="4" md="4">
        <v-switch v-model="item.active" label="Activo"></v-switch>
      </v-col>
    </v-row>
  </div>
</template>


<script>
export default {
  name: "banner-status",
  props: ["item"],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>
