<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-row class="pa-0 ma-0">
      <v-col cols="12" md="8">
        <h1 class="mb-1">In App Messaging</h1>
      </v-col>

      <!-- <v-col cols="12" md="6" class="py-0">
        <v-select
          v-model="selectedCountry"
          :items="countries"
          placeholder="Escoge el pais"
          outlined
          @change="getCities"
          item-text="name"
          item-value=".key"
          rounded
        />
      </v-col>

      <v-col cols="12" md="6" class="py-0">
        <v-select
          v-model="selectedCity"
          :items="cities"
          placeholder="Escoge la ciudad"
          outlined
          @change="getMessages"
          :disabled="!selectedCountry"
          item-text="name"
          item-value=".key"
          rounded
        />
      </v-col> -->
      <v-col cols="12" sm="12" md="12">
        <v-row justify="end">
          <v-btn
            @click="addMessaging"
            color="primary"
            class="ma-2 white--text"
            :loading="loading"
          >
            <v-icon right dark class="mr-3">fas fa-plus</v-icon>
            Agregar
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-data-table
          :headers="headers"
          :items="messages"
          :search="$store.state.search"
          :items-per-page="15"
          :loading="loading"
          sort-by="createdAt"
          class="mt-5"
          sort-desc
          item-key=".key"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            <span class>{{ item.createdAt | filterDate }}</span>
          </template>
          <template v-slot:[`item.dateStart`]="{ item }">
            <span class>{{ item.dateStart | filterShortDate }}</span>
          </template>
          <template v-slot:[`item.dateEnd`]="{ item }">
            <span class>{{ item.dateEnd | filterShortDate }}</span>
          </template>

          <template v-slot:[`item.type`]="{ item }">
            <span class>{{ item.type | filterType }}</span>
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <v-switch
              @change="switchControlChanged({ active: item.active }, item)"
              v-model="item.active"
              :loading="loading"
            >
            </v-switch>
          </template>

          <template v-slot:[`item.image`]="{ item }">
            <v-avatar v-if="item.image && item.image.original">
              <img :src="item.image.original" :lazy-src="item.image.loading" />
            </v-avatar>
          </template>

          <template v-slot:[`item.options`]="{ item }">
            <v-btn @click="editMessaging(item)" small color="primary">
              Editar
            </v-btn>

            <v-btn
              @click="deleteMessaging(item)"
              small
              class="ml-3 white--text"
              color="red darken-4"
            >
              Eliminar
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog persistent v-model="addDialog" v-if="addDialog" max-width="850px">
      <Add
        :selectedItem="selectedItem"
        @cancel="addDialog = false"
        @success="handleSuccess"
      />
    </v-dialog>

    <v-dialog max-width="400" v-model="deleteDialog" v-if="deleteDialog">
      <v-card>
        <v-card-title class="headline"
          >Confirmar acción
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="deleteDialog = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          ¿Está seguro que desea eliminar
          <span class="font-weight-bold">{{ selectedItem.name }}</span
          >?
        </v-card-text>

        <v-card-actions style="justify-content: center">
          <v-btn
            color="red darken-4"
            width="90%"
            style="border-radius: 50px"
            class="mb-3"
            dark
            :loading="loading"
            @click="confirmDelete"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { db } from "@/firebase";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import Add from "./add";
import { mapState } from "vuex";

export default {
  name: "IAM",
  components: {
    Add,
  },
  data() {
    return {
      loading: true,
      modal: false,
      date: [
        `${moment()
          .tz("America/Tegucigalpa")
          .subtract(6, "hours")
          .toISOString()
          .substr(0, 10)}`,
        `${moment()
          .tz("America/Tegucigalpa")
          .subtract(6, "hours")
          .toISOString()
          .substr(0, 10)}`,
      ],
      snackbar: false,
      snackbarText: "",
      messages: [],
      countries: [],
      selectedCity: "",
      selectedCountry: "",
      cities: [],
      selectedItem: null,
      addDialog: false,
      deleteDialog: false,
      headers: [
        {
          value: "image",
          class: "nowrap",
          width: "100px",
        },
        {
          text: "Nombre",
          value: "name",
          class: "nowrap",
          width: "250px",
        },
        {
          text: "Tipo",
          value: "type",
        },
        {
          text: "Fecha inicio",
          value: "dateStart",
        },

        {
          text: "Fecha final",
          value: "dateEnd",
        },

        {
          text: "Hora inicio",
          value: "timeStart",
        },

        {
          text: "Hora final",
          value: "timeEnd",
        },

        {
          text: "Activo",
          value: "active",
        },

        {
          text: "Fecha de creación",
          value: "createdAt",
          class: "nowrap",
        },
        {
          value: "options",
          width: "200px",
        },
      ],
    };
  },
  filters: {
    filterDate: function (timestamp) {
      if (!timestamp) {
        return "n/a";
      }
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("DD/MM/YYYY, hh:mm A ");
    },

    filterShortDate(timestamp) {
      if (!timestamp) {
        return "n/a";
      }
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("DD/MM/YYYY");
    },

    filterType(type) {
      let types = {
        business: "Comercio",
        product: "Producto",
      };

      return types[type] ? types[type] : "Desconocido";
    },
  },
  computed: {
    ...mapState(["user"]),
    today() {
      return new Date().toISOString().substr(0, 10);
    },
  },

  async mounted() {
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;
    this.$store.commit("setSearchTerm", "");

    await this.$binding(
      "countries",
      db.collection("countries").where("deleted", "==", false)
    );

    await this.$binding(
      "messages",
      db.collection("inAppCustomMessaging").where("deleted", "==", false)
      //   .where("cities", "array-contains", this.selectedCity)
    );

    this.loading = false

    // this.getMessages();
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text.smsCode).then(
        () => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },

    confirmDelete() {
      this.loading = true;
      db.collection("inAppCustomMessaging")
        .doc(this.selectedItem[".key"])
        .update({
          deleted: true,
          deletedAt: new Date(),
          deletedBy: this.user[".key"],
        })
        .then((r) => {
          this.snackbarText = "Información actualizada correctamente.";
          this.snackbar = true;
          this.loading = false;
          this.deleteDialog = false;
        })
        .catch((error) => {
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
          this.loading = false;
        });
    },

    handleSuccess() {
      this.snackbarText = "Banner agregado exitosamente.";
      this.snackbar = true;
      this.addDialog = false;
    },

    getCities(countryId) {
      this.messages = [];
      this.selectedCity = "";
      this.$binding(
        "cities",
        db
          .collection("cities")
          .where("deleted", "==", false)
          .where("countryId", "==", countryId)
      );
    },

    // async getMessages() {
    //   this.loading = true;
    //   await this.$binding("messages", db.collection("inAppCustomMessaging"));

    //   this.loading = false;
    // },

    // updateDateFilter() {
    //   if (this.date.length > 1) {
    //     if (moment(this.date[0], "YYYY-MM-DD") > new Date(this.date[1])) {
    //       this.date = [this.date[1], this.date[0]];
    //     }
    //   } else {
    //     this.date = [this.date[0], this.date[0]];
    //   }

    //   this.getMessages();
    // },

    addMessaging() {
      this.addDialog = true;
      this.selectedItem = null;
    },

    editMessaging(item) {
      this.addDialog = true;
      this.selectedItem = item;
    },

    deleteMessaging(item) {
      this.deleteDialog = true;
      this.selectedItem = item;
    },

    switchControlChanged(data, item) {
      if (data && item) {
        this.loading = true;
        db.collection("inAppCustomMessaging")
          .doc(item[".key"])
          .update(data)
          .then(() => {
            this.loading = false;
            this.snackbarText = "Información actualizada correctamente.";
            this.snackbar = true;
          })
          .catch((err) => {
            this.loading = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
            item.active = !data.active;
          });
      }
    },

    title() {
      return "Selecciona las fechas a filtrar";
    },
  },
};
</script>
